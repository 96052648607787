* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
}

body {
    width: 100vw;
    height: 100vh;
    position: fixed;
}

#root {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #141414, black, #141414);
    display: flex;
    justify-content: center;
    align-items: center;
}